<template>
  <div class="bar-wrap">
    <div @click="selectType">类型<img src="@img/down.png" alt="" /></div>
    <div @click="selectStatus">状态<img src="@img/down.png" alt="" /></div>
    <div @click="selectDate">时间<img src="@img/down.png" alt="" /></div>
    <CommonActionSheet
      v-model="showType"
      :actions="actions"
      cancel-text="取消"
      :closeOnClickAction="true"
      @ok="ok"
    />
    <CommonActionSheet
      v-model="showStatus"
      :actions="actions"
      :defaultValue="searchParams ? searchParams.status : null"
      cancel-text="取消"
      :closeOnClickAction="true"
      @ok="ok"
    />

    <van-popup v-model="showDate" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @cancel="showDate = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import CommonActionSheet from "@components/CommonActionSheet.vue";
import moment from "moment";
import { getRegisterTime } from "../http/api";
export default {
  name: "dropdown-bar",

  components: {
    CommonActionSheet,
  },

  props: {
    searchParams: {
      type: Object,
      default: () => {
        // default
      },
    },
    selectTypeFilter: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      actions: [
        {
          id: 1,
          name: "全部",
        },
        {
          id: 26,
          name: "电子普票",
        },
        {
          id: 28,
          name: "电子专票",
        },
        {
          id: 32,
          name: "全电普票",
        },
        {
          id: 31,
          name: "全电专票",
        },
      ],
      type: "",
      showType: false,
      showStatus: false,
      showDate: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
    };
  },

  methods: {
    selectType() {
      this.type = "infoType";
      if (this.selectTypeFilter) {
        if (this.selectTypeFilter == "receipt") {
          this.actions = [
            {
              id: null,
              name: "全部",
              selectType: "receipt",
            },
            {
              id: 1001,
              name: "银行回单",
              selectType: "receipt",
            },
            {
              id: 1005,
              name: "发票",
              selectType: "receipt",
            },
            {
              id: 1002,
              name: "合同",
              selectType: "receipt",
            },
            {
              id: 1003,
              name: "库存单据",
              selectType: "receipt",
            },
            {
              id: 1004,
              name: "证照",
              selectType: "receipt",
            },
            {
              id: 1100,
              name: "其他",
              selectType: "receipt",
            },
          ];
        } else {
          this.actions = [
            {
              id: null,
              name: "全部",
              selectType: "invoice",
            },
            {
              id: 26,
              name: "电子普票",
              selectType: "invoice",
            },
            {
              id: 28,
              name: "电子专票",
              selectType: "invoice",
            },
            {
              id: 32,
              name: "全电普票",
              selectType: "invoice",
            },
            {
              id: 31,
              name: "全电专票",
              selectType: "invoice",
            },
          ];
        }
      } else {
        this.actions = [
          {
            id: null,
            name: "全部",
          },
          {
            id: 1001,
            name: "银行回单",
            selectType: "receipt",
          },
          {
            id: 1005,
            name: "发票",
            selectType: "receipt",
          },
          {
            id: 1002,
            name: "合同",
            selectType: "receipt",
          },
          {
            id: 1003,
            name: "库存单据",
            selectType: "receipt",
          },
          {
            id: 1004,
            name: "证照",
            selectType: "receipt",
          },
          {
            id: 1100,
            name: "其他",
            selectType: "receipt",
          },
          {
            id: 26,
            name: "电子普票",
            selectType: "invoice",
          },
          {
            id: 28,
            name: "电子专票",
            selectType: "invoice",
          },
          {
            id: 32,
            name: "全电普票",
            selectType: "invoice",
          },
          {
            id: 31,
            name: "全电专票",
            selectType: "invoice",
          },
        ];
      }
      this.showType = !this.showType;
    },

    selectStatus() {
      this.type = "status";
      this.actions = this.selectTypeFilter
        ? [
            {
              id: null,
              name: "全部",
            },
            {
              id: 0,
              name: "未提交",
            },
            {
              id: 2,
              name: "已提交",
            },
            {
              id: 1,
              name: "已驳回",
            },
          ]
        : [
            {
              id: 10,
              name: "全部",
            },
            {
              id: 0,
              name: "未提交",
            },
            {
              id: 1,
              name: "已驳回",
            },
          ];
      this.showStatus = !this.showStatus;
    },

    selectDate() {
      getRegisterTime().then((res) => {
        this.minDate = new Date(res.data.createdDate);
        this.type = "dateRange";
        this.showDate = !this.showDate;
      });
    },

    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },

    onConfirm(val) {
      let startDate = val;
      let endDate = new Date(moment(val).endOf("month"));
      this.showDate = false;
      this.$emit("change", { startDate, endDate, type: this.type });
    },

    ok(item) {
      this.$emit("change", { ...item, type: this.type });
    },
  },
};
</script>

<style lang="less" scoped>
.bar-wrap {
  margin-bottom: 12px;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 15px;
  line-height: 21px;
  color: #666666;
  img {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    vertical-align: middle;
  }
}
</style>
