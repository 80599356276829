<template>
  <div class="common-list-wrap">
    <div class="common-list">
      <van-row class="header" v-if="showTop">
        <van-col span="6">
          <CommonIcon
            @click.native="listOptions('submit')"
            url="submit.png"
            text="提交财务"
          />
        </van-col>
        <!-- 暂时隐藏 -->
        <!-- <van-col span="6">
          <CommonIcon
            @click.native="listOptions('share')"
            url="share.png"
            text="分享"
          />
        </van-col> -->
        <!-- <van-col span="6">
          <CommonIcon
            @click.native="listOptions('export')"
            url="export.png"
            text="导出"
          />
        </van-col> -->
        <van-col span="6" v-show="type == 'receipt'">
          <CommonIcon
            @click.native="listOptions('changeType')"
            url="more.png"
            text="指定类型"
          />
        </van-col>
      </van-row>
      <DropdownBar
        :searchParams="searchParams"
        :selectTypeFilter="selectTypeFilter"
        @change="handleChange"
      />

      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
        >
          <template v-slot:finished>
            <div class="no-data bg-white lh-22" v-if="dataList.length == 0">
              <img src="@img/no-data.png" alt="" />
              <div>暂无数据~</div>
              <div>您需要去首页收取票据</div>
            </div>
            <div v-else>没有更多了</div>
          </template>
          <div class="invoice-list">
            <van-checkbox-group v-model="result" ref="checkboxGroup">
              <template v-for="item in dataList">
                <div :key="item.id">
                  <div
                    class="error-tip"
                    v-if="item.rejectMessage != '' && item.rejectMessage != '驳回'"
                  >
                    <van-icon name="warning" size="15" color="#F6404E" />
                    {{ item.rejectMessage }}
                  </div>
                  <van-swipe-cell
                    ref="swipcell"
                    stop-propagation
                    :disabled="!cellOperabled || item.status == 2"
                    :name="item.id"
                    :before-close="beforeClose"
                    @open="swiped = true"
                    @close="swiped = false"
                  >
                    <van-checkbox
                      :name="item"
                      :class="[
                        'invoice-card',
                        item.rejectMessage != '' && item.rejectMessage != '驳回'
                          ? 'has-error'
                          : '',
                      ]"
                      label-disabled
                    >
                      <div @click="view(item)" v-if="item.type == 'receipt'">
                        <div
                          class="list-item van-ellipsis"
                          style="width: 230px; padding-right: 14px"
                        >
                          <span>文件名：</span>
                          <span>{{ item.file.fileName }}</span>
                          <img
                            class="view"
                            src="@img/view.png"
                            alt=""
                            v-show="
                              ['jpg', 'png', 'jpeg', 'pdf', 'ofd'].includes(
                                item.file.extension
                              )
                            "
                          />
                        </div>
                        <div class="list-item">
                          <span>上传日期：</span>
                          <span>
                            {{
                              new Date(item.createdDate)
                                | dateFilter("yyyy-MM-dd")
                            }}
                          </span>
                        </div>
                        <div class="list-item" style="word-break: break-all">
                          <span>事项：</span>
                          <span>
                            {{ item.reason }}
                          </span>
                        </div>
                        <div class="tags">
                          <div
                            class="tag type"
                            @click.stop="changeSingleType(item)"
                          >
                            {{ item.info.receiptTypeDescription }}
                            <img src="~@img/arrow-down-circle.png" alt="" />
                          </div>
                        </div>
                        <div class="card-status" v-show="item.status == 0">
                          <span>未提交</span>
                        </div>
                        <div
                          class="card-status finished"
                          v-show="item.status == 2"
                        >
                          <span>已提交</span>
                        </div>
                        <div
                          class="card-status error"
                          v-show="item.status == 1"
                        >
                          <span>已驳回</span>
                        </div>
                      </div>
                      <div @click="view(item)" v-else>
                        <div
                          class="list-item van-ellipsis"
                          style="width: 230px; padding-right: 14px"
                        >
                          <span>文件名：</span>
                          <span>{{ item.file.fileName }}</span>
                          <img
                            class="view"
                            src="@img/view.png"
                            alt=""
                            v-show="
                              ['jpg', 'png', 'jpeg', 'pdf', 'ofd'].includes(
                                item.file.extension
                              )
                            "
                          />
                        </div>
                        <div
                          class="list-item van-ellipsis"
                          style="width: 240px"
                        >
                          <span>购买方：</span>
                          <span>
                            {{ item.info.buyerName }}
                          </span>
                        </div>
                        <div
                          class="list-item van-ellipsis"
                          style="width: 240px"
                        >
                          <span>销售方：</span>
                          <span>
                            {{ item.info.sellerName }}
                          </span>
                        </div>
                        <div class="list-item">
                          <span>金额：</span>
                          <span>
                            {{
                              item.info.taxInclusiveTotalAmount | moneyFilter
                            }}
                          </span>
                          <span style="margin-left: 10px">日期：</span>
                          <span>
                            {{
                              new Date(item.createdDate)
                                | dateFilter("yyyy-MM-dd")
                            }}
                          </span>
                        </div>
                        <div class="list-item" style="word-break: break-all">
                          <span>事项：</span>
                          <span>
                            {{ item.reason }}
                          </span>
                        </div>
                        <div class="tags" @click.stop="() => {}">
                          <div class="tag type">
                            {{ item.info.invoiceTypeDescription }}
                          </div>
                          <div
                            class="tag checking"
                            v-show="item.info.status == 0"
                          >
                            查验中
                          </div>
                          <div
                            class="tag checked"
                            v-show="item.info.status == 1"
                          >
                            <van-icon name="passed" style="margin-right: 6px" />
                            已查验
                          </div>
                          <div
                            class="tag checkfailed"
                            v-show="item.info.status == 2"
                          >
                            查验失败
                          </div>
                          <div
                            class="re-btn"
                            v-show="item.info.status == 2"
                            @click="reCheck"
                          >
                            <img src="@img/refresh.png" alt="" />
                          </div>
                        </div>
                        <div class="card-status" v-show="item.status == 0">
                          <span>未提交</span>
                        </div>
                        <div
                          class="card-status finished"
                          v-show="item.status == 2"
                        >
                          <span>已提交</span>
                        </div>
                        <div
                          class="card-status error"
                          v-show="item.status == 1"
                        >
                          <span>已驳回</span>
                        </div>
                      </div>
                    </van-checkbox>
                    <template #right>
                      <van-button
                        square
                        text="编辑"
                        color="#33c759"
                        class="action-button"
                        @click="handleEdit(item)"
                      />
                      <van-button
                        square
                        text="删除"
                        color="#FF7500"
                        class="action-button"
                        @click="handleDelete(item)"
                      />
                    </template>
                  </van-swipe-cell>
                </div>
              </template>
            </van-checkbox-group>
          </div>
        </van-list>
      </van-pull-refresh>

      <van-image-preview v-model="showImg" :images="images" @closed="onClosed">
      </van-image-preview>

      <van-popup v-model="showpdf" style="width: 80%; height: 80%">
        <template v-if="showpdf">
          <div id="previewpdf"></div>
        </template>
      </van-popup>

      <van-popup v-model="showofd" style="width: 100%; height: 50%">
        <div v-html="ofdurl" />
      </van-popup>

      <CommonActionSheet
        v-model="showType"
        title="选择文件类型"
        cancel-text="取消"
        :defaultValue="defaultValue"
        :actions="actions"
        :closeable="true"
        :closeOnClickAction="true"
        @ok="ok"
      />
    </div>
    <div v-if="showBottom" class="bottom">
      <van-button type="primary" @click="onSubmit">
        {{ bottomBtnText }}
      </van-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CommonActionSheet from "@components/CommonActionSheet.vue";
import CommonIcon from "@components/CommonIcon.vue";
import DropdownBar from "@components/CommonDropdownBar.vue";
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
import {
  getBillList,
  deleteBill,
  changeReceiptType,
  filePreview,
  getAccountSets,
} from "../http/api";

export default {
  name: "CommonList",

  components: {
    CommonActionSheet,
    DropdownBar,
    CommonIcon,
  },

  props: {
    type: {
      type: String,
      default: null,
    },
    showTop: {
      type: Boolean,
      default: true,
    },
    cellOperabled: {
      type: Boolean,
      default: true,
    },
    showBottom: {
      type: Boolean,
      default: false,
    },
    bottomBtnText: {
      type: String,
      default: "提交",
    },
    selectTypeFilter: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      dataList: [],
      result: [],
      searchParams: {
        type: this.type,
        pageSize: 10,
        currentPage: 1,
      },
      loading: false,
      error: false,
      finished: false,
      refreshing: false,
      handleType: "edit",
      showImg: false,
      images: [],
      showpdf: false,
      pdfh5: null,
      showofd: false,
      ofdurl: null,
      showType: false,
      actions: [
        {
          id: 1001,
          name: "银行回单",
        },
        {
          id: 1002,
          name: "合同",
        },
        {
          id: 1003,
          name: "库存单据",
        },
        {
          id: 1004,
          name: "证照",
        },
        {
          id: 1005,
          name: "发票",
        },
        {
          id: 1100,
          name: "其他",
        },
      ],
      activeData: [],
      defaultValue: null,
      swiped: false,
      source: null,
    };
  },

  mounted() {
    if (!this.selectTypeFilter) {
      this.searchParams.status = 10;
      this.searchParams.invoiceStatus = 1;
    }
  },

  filters: {
    moneyFilter(val) {
      return val ? val.toFixed(2) : null;
    },
  },

  methods: {
    beforeClose({ name, position, instance }) {
      switch (position) {
        case "cell":
        case "outside":
          instance.close();
          break;
        case "right":
          // console.log(name, position, instance);
          if (this.handleType == "edit") {
            this.$emit("edit", name);
          } else {
            this.$dialog
              .confirm({
                message: "确定删除当前票据吗？",
                confirmButtonText: "确定",
                confirmButtonColor: "#33C759",
              })
              .then(() => {
                deleteBill(name).then((res) => {
                  if (!res.statusCode) {
                    this.$toast(res.message);
                    return;
                  }
                  instance.close();
                  this.$toast("删除成功");
                  this.refreshing = true;
                  this.onRefresh();
                });
              })
              .catch(() => {
                // console.log("delete");
              });
          }
          break;
      }
      return;
    },
    handleEdit() {
      this.handleType = "edit";
    },
    handleDelete() {
      this.handleType = "delete";
    },

    onLoad() {
      let this_ = this;
      if (this.refreshing) {
        this.dataList = [];
        this.result = [];
        this.refreshing = false;
        this.cancelRequest();
      }
      getBillList(
        { ...this.searchParams },
        {
          cancelToken: new axios.CancelToken(function executor(c) {
            this_.source = c;
          }),
        }
      )
        .then((res) => {
          this.searchParams.currentPage++;
          this.dataList.push(...res.data.data);
          this.loading = false;
          if (this.dataList.length >= res.data.totalCount) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.error = true;
        });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.error = false;
      this.searchParams.currentPage = 1;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },

    cancelRequest() {
      if (typeof this.source === "function") {
        this.source("cancel request");
      }
    },

    handleChange(data) {
      if (data.type == "dateRange") {
        this.searchParams.dateRange = [data.startDate, data.endDate];
      } else {
        if (data.type == "infoType") {
          this.searchParams.type = data.selectType;
        }
        this.searchParams[data.type] = data.id;
      }
      this.refreshing = true;
      this.onRefresh();
    },

    listOptions(type) {
      if (this.result.length == 0) {
        let message = "请先勾选单据";
        if (this.searchParams.type == "invoice") {
          message = "请先勾选发票";
        }
        this.$toast(message);
        return;
      }
      switch (type) {
        case "submit":
          getAccountSets().then((res) => {
            if (!res.statusCode) {
              this.$toast(res.message);
              return;
            }
            if (res.data.length == 0) {
              this.$dialog
                .confirm({
                  className: "tip-model",
                  message: "您还未绑定财务软件",
                  theme: "round-button",
                  confirmButtonText: "去绑定",
                  confirmButtonColor: "#33C759",
                  cancelButtonColor: "#ffffff",
                })
                .then(() => {
                  let url = "/pages/share/index";
                  this.$wechatMiniProgramNavigateTo({ url });
                })
                .catch(() => {
                  // on cancel
                });
            } else {
              let subFlag = false;
              let checkFlag = false;
              this.result.forEach((item) => {
                if (item.status == 2) {
                  subFlag = true;
                }
                if (item.info.status && item.info.status == 2) {
                  checkFlag = true;
                }
              });
              if (subFlag) {
                this.$toast("存在已提交的票据");
                return;
              }
              if (checkFlag) {
                this.$toast("存在查验失败的票据");
                return;
              }
              this.$router.push({
                name: "submitFinance",
                query: {
                  bills: JSON.stringify(this.result),
                },
              });
            }
          });
          break;
        case "share":
          console.log("share");
          break;
        case "export": {
          let files = [];
          files = this.result.map((item) => {
            return {
              id: item.id,
              fileName: item.file.fileName + "." + item.file.extension,
            };
          });
          console.log(files);
          let url = "/pages/download/index?files=" + JSON.stringify(files);
          this.$hrefTo(url);
          break;
        }
        case "changeType":
          this.changeAllType();
          break;

        default:
          break;
      }
    },

    view(data) {
      if (!["jpg", "png", "jpeg", "pdf", "ofd"].includes(data.file.extension)) {
        return;
      }
      if (this.swiped) {
        return;
      }
      if (
        data.file.extension == "jpg" ||
        data.file.extension == "png" ||
        data.file.extension == "jpeg"
      ) {
        this.images = [
          `${process.env.VUE_APP_URL}/spb/bill/preview/${data.id}`,
        ];
        this.showImg = true;
      }
      if (data.file.extension == "pdf") {
        this.showpdf = true;
        setTimeout(() => {
          this.pdfh5 = new Pdfh5("#previewpdf", {
            pdfurl: `${process.env.VUE_APP_URL}/spb/bill/preview/${data.id}`,
            cMapUrl:"https://unpkg.com/pdfjs-dist@4.0.379/cmaps/",
          });
        }, 0);
      }
      if (data.file.extension == "ofd") {
        this.showofd = true;
        filePreview(data.id).then((res) => {
          this.ofdurl = res;
        });
      }
    },

    onClosed() {
      this.images = [];
    },

    ok(data) {
      changeReceiptType({
        bills: this.activeData.map((item) => item.id),
        receiptType: data.id,
      }).then((res) => {
        if (!res.statusCode) {
          this.$toast(res.message);
          return;
        }
        this.$toast(res.message);
        this.refreshing = true;
        this.onRefresh();
      });
    },

    changeSingleType(data) {
      if (!this.cellOperabled) {
        return;
      }
      if (data.status == 2) {
        this.$toast("已提交的单据不可修改类型");
        return;
      }
      this.activeData = [data];
      this.defaultValue = data.info.receiptType;
      this.showType = true;
    },

    changeAllType() {
      if (!this.cellOperabled) {
        return;
      }
      let flag = false;
      this.result.forEach((item) => {
        if (item.status == 2) {
          flag = true;
        }
      });
      if (flag) {
        this.$toast("存在已提交的票据");
        return;
      }
      this.activeData = this.result;
      this.showType = true;
    },

    reCheck() {
      this.refreshing = true;
      this.onRefresh();
    },

    onSubmit() {
      if (this.result.length == 0) {
        this.$toast("请先勾选票据");
        return;
      }
      this.$emit("onSubmit", this.result);
    },
  },
};
</script>

<style lang="less" scoped>
.common-list-wrap {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.common-list {
  padding: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  // padding: 12px 12px 0;
  .van-pull-refresh {
    overflow: auto;
    flex: 1;
  }
  .header {
    margin: 8px 0 20px;
    border-radius: 8px;
    .van-col {
      text-align: center;
    }
    /deep/ .icon {
      .img {
        width: 50px;
        height: 50px;
      }
    }
  }
  .error-tip {
    margin-bottom: 9px;
    color: #666666;
    font-size: 12px;
    line-height: 17px;
  }
  .invoice-card {
    border: 1px solid transparent;
  }
  .has-error {
    border-color: rgba(246, 64, 78, 0.5);
  }
  .tags {
    position: absolute;
    bottom: 12px;
    right: 12px;
    display: flex;
    align-items: center;
    .tag {
      display: inline-flex;
      align-items: center;
      padding: 0 9px;
      border-radius: 2px;
      font-size: 12px;
      background: #f2fff2;
      color: #06c05f;
      & + .tag {
        margin-left: 12px;
      }
      &.type {
        img {
          width: 12px;
          height: 12px;
          margin-left: 4px;
        }
      }
    }
    .checking {
      background: #fffaf5;
      color: #ff7500;
    }
    .checked {
      background: #f5f9ff;
      color: #3d7fff;
    }
    .checkfailed {
      background: #fff8f9;
      color: #ff576b;
    }
    .re-btn {
      margin-left: 20px;
      display: inline-flex;
      align-items: center;
      img {
        display: inline-block;
        width: 20px;
        height: 20px;
      }
    }
  }
}
.action-button {
  height: 100%;
}

.bottom {
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  background: #ffffff;
  .van-button {
    width: 100%;
    height: 40px;
    background: #33c759;
    border-radius: 20px;
    font-size: 16px;
  }
}
.view {
  position: absolute;
  top: 5px;
  right: 0;
  width: 14px;
  height: 11px;
}
</style>

<style lang="less">
.van-dialog__message {
  font-size: 16px;
}
</style>
